
function About() {
  return (
      <div class="pagebackground">
      <h1>
      About COUNTRY VIDEO GAMES
      </h1>
      <h2>We're in Kentucky</h2>
      <h2>Making applications for Windows, the Web, and beyond.</h2>      
      </div>
  );
}

export default About;

import './App.css'

function Corona() {
    return (
        <div class="pagebackground">
            <h1>CORONA is Easy and Fast.</h1>
            <h2>
                Integrated Application Database and Web Service Server
            </h2>
            <p>
                No need to full with a complex stack. Corona gives you a database server and http server
                right out of the box.  Fire it up, edit a file, and focus on your UI and your data.
            </p>
            <h2>
                Instant Business Service
            </h2>
            <p>
                Easily set up a business with automatic sign on and enrollment for internal and external users.
            </p>
            <h2>
                Objects of Anything
            </h2>
            <p>
                Create classes, then make objects out of them. Use Corona's own easy database, or use
                your favorite ODBC capable database as a back end store.
            </p>
            <h2>
                Rich Querying
            </h2>
            <p>
                Join, filter, and project any collection from any source to any other collection.
            </p>
            <h2>
                Easy set up
            </h2>
            <p>
                Edit a simple configuration file to build your entire api and databases. 
            </p>
            <h2>
                Enterprise Designed
            </h2>
            <p>
                Perfect for large or small businesses, Corona can be used to build line of business applications from enterprise data sources safely with pro-features like automatic use of environment variables for sensitive passwords,
                separate config files that let you say where your database will be stored and what ports it will use.
            </p>
            <h2>
                Windows Performance
            </h2>
            <p>
                Microsoft Windows has fast asynchronous i/o internally, and Corona uses all of it, similar to what Node.js and libuv does, but, 
                with the disk i/o also included in that same asynchronous i/o stack.
            </p>
            <h2>Download</h2>
              <p>Create web apis instantly from a class definition file.</p>
              <p>Download zip, extract to folder, and either run from the terminal or invoke the batch.</p>
              <p>
                  <a href="coronaserver.zip">Download Corona Preview (11/28/2024)</a>
              </p>
        </div>
    );
}

export default Corona;
